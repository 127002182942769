/*  ==========================================================================
    App Styles

    Configuration     - Configuration of Priva Styles
    Main              - Priva Styles
    Extensions        - App specific styles (reviewed by UX)
    Overrides         - Temporarily structured styles (not reviewed by UX)
    Shame             - Quick and dirty implementation of styles.

    DO      -   Divide styling into logical semantic folders and partials.
            -   Try to avoid adding unstructured "shame" styling.

    DON'T   -   Add any style declarations to this file.
            -   Add @import statements to this file.
    ========================================================================== */

/* Configuration */
@import 'configuration/configuration';

/* Main */
@import 'priva';

/* Extensions */
@import 'extensions/extensions';

/* Overrides */
@import 'overrides/overrides';
