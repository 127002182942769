/*  ==========================================================================
    Priva icons
    ========================================================================== */

/* Load Priva icon font */
@font-face {
    @include fontdef-icon($font-path, $priva-icon-font-name, $priva-icon-font-type, $priva-icon-font-version);

    font-family: $priva-icon-font-family;
    font-style: $priva-icon-font-style;
    font-weight: $priva-icon-font-weight;
}

/* Load Priva icon font */
.icon {
    /* render multi-path icons correctly without having to remove all whitespace between path span elements */
    display: inline-flex;
    font-family: $priva-icon-font-name;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;

    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    text-transform: none;
    vertical-align: middle;

    /* do not verbalize by screen readers
        > this feature is not implemented yet > use ARIA for now */
    speak: none;

    &::before {
        position: relative;
        z-index: $z-index-above;
    }

    &-inline {
        display: inline;
    }

    /*  Color
    TODO: loop through list of colors
    ========================================================================== */

    &-color-white {
        color: $color-white;
    }

    &-color-black {
        color: $color-black-900;
    }

    &-color-gray-dark {
        color: $color-black-600;
    }

    &-color-gray {
        color: $color-black-300;
    }

    &-color-gray-light {
        color: $color-black-100;
    }

    /* text colors */
    &-color-text {
        color: $color-text;
    }

    &-color-text-soft {
        color: $color-text-soft;
    }

    &-color-text-muted {
        color: $color-text-muted;
    }

    /* semantic colors */
    &-color-primary {
        color: $color-main;
    }

    &-color-secondary {
        color: $color-accent-dark;
    }

    &-color-success {
        color: $color-success;
    }

    &-color-info {
        color: $color-info;
    }

    &-color-warning {
        color: $color-warning;
    }

    &-color-danger {
        color: $color-danger;
    }

    /*  Size
    ========================================================================== */

    &-xxs {
        font-size: $icon-font-size-xxs;
    }

    &-xs {
        font-size: $icon-font-size-xs;
    }

    &-sm {
        font-size: $icon-font-size-sm;
    }

    &-md {
        font-size: $icon-font-size-base;
    }

    &-lg {
        font-size: $icon-font-size-lg;
    }

    &-xl {
        font-size: $icon-font-size-xl;
    }
}

/*  Stacked icons

    HTML example:
    <span class="stacked-icon icon-md"><span aria-hidden="true" class="icon icon-sunrise-sun"></span><span aria-hidden="true" class="icon icon-sunrise-caret"></span></span>

    CSS mixin example
    @include stacked-icon(sunrise-sun sunrise-caret, $color-warning $color-secondary);
    ========================================================================== */

.stacked-icon {
    position: relative;
    display: inline-block;
    line-height: 1em;
    text-align: center;
    vertical-align: middle;

    .icon {
        position: absolute;
        top: 0;
        left: 0;
    }

    .icon-block {
        display: block;
    }
}

.stacked-icon-text {
    position: relative;
    z-index: $z-index-above;
    color: $color-white;
}

/*  Priva icons v1.37
    ========================================================================== */

.icon-linegraph-lg {
    &::before {
        content: unicode($icon-linegraph-lg);
    }
}

.icon-portal2 {
    &::before {
        content: unicode($icon-portal2);
    }
}

.icon-logout {
    &::before {
        content: unicode($icon-logout);
    }
}

.icon-sun-lg {
    &::before {
        content: unicode($icon-sun-lg);
    }
}

.icon-air-outlet-lg {
    &::before {
        content: unicode($icon-air-outlet-lg);
    }
}

.icon-manifold-lg {
    &::before {
        content: unicode($icon-manifold-lg);
    }
}

.icon-gasmotor-lg {
    &::before {
        content: unicode($icon-gasmotor-lg);
    }
}

.icon-heatexchanger-lg {
    &::before {
        content: unicode($icon-heatexchanger-lg);
    }
}

.icon-well-hot-cold-lg {
    &::before {
        content: unicode($icon-well-hot-cold-lg);
    }
}

.icon-well-cold-lg {
    &::before {
        content: unicode($icon-well-cold-lg);
    }
}

.icon-well-hot-lg {
    &::before {
        content: unicode($icon-well-hot-lg);
    }
}

.icon-irrigation-valve-lg {
    &::before {
        content: unicode($icon-irrigation-valve-lg);
    }
}

.icon-waterpipe-lg {
    &::before {
        content: unicode($icon-waterpipe-lg);
    }
}

.icon-par-lg {
    &::before {
        content: unicode($icon-par-lg);
    }
}

.icon-co2transport-lg {
    &::before {
        content: unicode($icon-co2transport-lg);
    }
}

.icon-airhandling-lg {
    &::before {
        content: unicode($icon-airhandling-lg);
    }
}

.icon-cropmonitor-lg {
    &::before {
        content: unicode($icon-cropmonitor-lg);
    }
}

.icon-airinlet-lg {
    &::before {
        content: unicode($icon-airinlet-lg);
    }
}

.icon-pad-and-fan-lg {
    &::before {
        content: unicode($icon-pad-and-fan-lg);
    }
}

.icon-drain-lg {
    &::before {
        content: unicode($icon-drain-lg);
    }
}

.icon-airmixing-lg {
    &::before {
        content: unicode($icon-airmixing-lg);
    }
}

.icon-radiation-sum-lg {
    &::before {
        content: unicode($icon-radiation-sum-lg);
    }
}

.icon-ground-humidity-lg {
    &::before {
        content: unicode($icon-ground-humidity-lg);
    }
}

.icon-managementinformation {
    &::before {
        content: unicode($icon-managementinformation);
    }
}

.icon-tcmanager .path1 {
    &::before {
        content: unicode($icon-tcmanager-path1);
        color: rgb(0 160 186);
    }
}

.icon-tcmanager .path2 {
    &::before {
        content: unicode($icon-tcmanager-path2);
        margin-left: -1em;
        color: rgb(0 102 122);
    }
}

.icon-license-lg {
    &::before {
        content: unicode($icon-license-lg);
    }
}

.icon-manualoverride {
    &::before {
        content: unicode($icon-manualoverride);
    }
}

.icon-zone {
    &::before {
        content: unicode($icon-zone);
    }
}

.icon-help-outline {
    &::before {
        content: unicode($icon-help-outline);
    }
}

.icon-flame2 {
    &::before {
        content: unicode($icon-flame2);
    }
}

.icon-photosynthesis {
    &::before {
        content: unicode($icon-photosynthesis);
    }
}

.icon-weather {
    &::before {
        content: unicode($icon-weather);
    }
}

.icon-waterdrop {
    &::before {
        content: unicode($icon-waterdrop);
    }
}

.icon-bell {
    &::before {
        content: unicode($icon-bell);
    }
}

.icon-home {
    &::before {
        content: unicode($icon-home);
    }
}

.icon-portal {
    &::before {
        content: unicode($icon-portal);
    }
}

.icon-alarmservice {
    &::before {
        content: unicode($icon-alarmservice);
        color: #e2395d;
    }
}

.icon-labourinsight .path1 {
    &::before {
        content: unicode($icon-labourinsight-path1);
        color: rgb(128 109 39);
    }
}

.icon-labourinsight .path2 {
    &::before {
        content: unicode($icon-labourinsight-path2);
        margin-left: -1em;
        color: rgb(209 138 0);
    }
}

.icon-cropinsight .path1 {
    &::before {
        content: unicode($icon-cropinsight-path1);
        color: rgb(27 119 117);
    }
}

.icon-cropinsight .path2 {
    &::before {
        content: unicode($icon-cropinsight-path2);
        margin-left: -1em;
        color: rgb(30 152 138);
    }
}

.icon-operator .path1 {
    &::before {
        content: unicode($icon-operator-path1);
        color: rgb(37 117 141);
    }
}

.icon-operator .path2 {
    &::before {
        content: unicode($icon-operator-path2);
        margin-left: -1em;
        color: rgb(0 160 186);
    }
}

.icon-masterdata {
    &::before {
        content: unicode($icon-masterdata);
    }
}

.icon-provisioning {
    &::before {
        content: unicode($icon-provisioning);
    }
}

.icon-mapmaker {
    &::before {
        content: unicode($icon-mapmaker);
    }
}

.icon-gatewaysetup {
    &::before {
        content: unicode($icon-gatewaysetup);
    }
}

.icon-accessmanagement {
    &::before {
        content: unicode($icon-accessmanagement);
    }
}

.icon-sitemanager .path1 {
    &::before {
        content: unicode($icon-sitemanager-path1);
        color: rgb(37 117 141);
    }
}

.icon-sitemanager .path2 {
    &::before {
        content: unicode($icon-sitemanager-path2);
        margin-left: -1em;
        color: rgb(0 160 186);
    }
}

.icon-questionmark-xl {
    &::before {
        content: unicode($icon-questionmark-xl);
    }
}

.icon-red-cross-xl {
    &::before {
        content: unicode($icon-red-cross-xl);
    }
}

.icon-exclamationmark-xl {
    &::before {
        content: unicode($icon-exclamationmark-xl);
    }
}

.icon-infomark-xl {
    &::before {
        content: unicode($icon-infomark-xl);
    }
}

.icon-sensor {
    &::before {
        content: unicode($icon-sensor);
    }
}

.icon-triangle-open-top {
    &::before {
        content: unicode($icon-triangle-open-top);
    }
}

.icon-triangle-open-bottom {
    &::before {
        content: unicode($icon-triangle-open-bottom);
    }
}

.icon-box-open {
    &::before {
        content: unicode($icon-box-open);
    }
}

.icon-door {
    &::before {
        content: unicode($icon-door);
    }
}

.icon-window {
    &::before {
        content: unicode($icon-window);
    }
}

.icon-arrow-correction-left {
    &::before {
        content: unicode($icon-arrow-correction-left);
    }
}

.icon-arrow-correction-right {
    &::before {
        content: unicode($icon-arrow-correction-right);
    }
}

.icon-arrow-turn-left {
    &::before {
        content: unicode($icon-arrow-turn-left);
    }
}

.icon-arrow-turn-right {
    &::before {
        content: unicode($icon-arrow-turn-right);
    }
}

.icon-sunblinds {
    &::before {
        content: unicode($icon-sunblinds);
    }
}

.icon-open-closed {
    &::before {
        content: unicode($icon-open-closed);
    }
}

.icon-airquality2 {
    &::before {
        content: unicode($icon-airquality2);
    }
}

.icon-cooling {
    &::before {
        content: unicode($icon-cooling);
    }
}

.icon-light {
    &::before {
        content: unicode($icon-light);
    }
}

.icon-electricity2 {
    &::before {
        content: unicode($icon-electricity2);
    }
}

.icon-fan {
    &::before {
        content: unicode($icon-fan);
    }
}

.icon-settings-outline {
    &::before {
        content: unicode($icon-settings-outline);
    }
}

.icon-waterdrops {
    &::before {
        content: unicode($icon-waterdrops);
    }
}

.icon-flame {
    &::before {
        content: unicode($icon-flame);
    }
}

.icon-leaf3 {
    &::before {
        content: unicode($icon-leaf3);
    }
}

.icon-leaf {
    &::before {
        content: unicode($icon-leaf);
    }
}

.icon-temperature {
    &::before {
        content: unicode($icon-temperature);
    }
}

.icon-waterdrop-lg {
    &::before {
        content: unicode($icon-waterdrop-lg);
    }
}

.icon-calendar {
    &::before {
        content: unicode($icon-calendar);
    }
}

.icon-check-dropdown {
    &::before {
        content: unicode($icon-check-dropdown);
    }
}

.icon-cloud-download-lg {
    &::before {
        content: unicode($icon-cloud-download-lg);
    }
}

.icon-cloud-upload-lg {
    &::before {
        content: unicode($icon-cloud-upload-lg);
    }
}

.icon-appswitcher-arrowdown-lg {
    &::before {
        content: unicode($icon-appswitcher-arrowdown-lg);
    }
}

.icon-appswitcher-arrowup-lg {
    &::before {
        content: unicode($icon-appswitcher-arrowup-lg);
    }
}

.icon-energy-lg {
    &::before {
        content: unicode($icon-energy-lg);
    }
}

.icon-curtains-lg {
    &::before {
        content: unicode($icon-curtains-lg);
    }
}

.icon-ventilation-lg {
    &::before {
        content: unicode($icon-ventilation-lg);
    }
}

.icon-managementinformation-filled {
    &::before {
        content: unicode($icon-managementinformation-filled);
    }
}

.icon-managementinformation-lg {
    &::before {
        content: unicode($icon-managementinformation-lg);
    }
}

.icon-clock {
    &::before {
        content: unicode($icon-clock);
    }
}

.icon-timer-lg {
    &::before {
        content: unicode($icon-timer-lg);
    }
}

.icon-clock-lg {
    &::before {
        content: unicode($icon-clock-lg);
    }
}

.icon-tabel {
    &::before {
        content: unicode($icon-tabel);
    }
}

.icon-print {
    &::before {
        content: unicode($icon-print);
    }
}

.icon-trash {
    &::before {
        content: unicode($icon-trash);
    }
}

.icon-extensiontimer {
    &::before {
        content: unicode($icon-extensiontimer);
    }
}

.icon-heating-lg {
    &::before {
        content: unicode($icon-heating-lg);
    }
}

.icon-building {
    &::before {
        content: unicode($icon-building);
    }
}

.icon-humidity-lg {
    &::before {
        content: unicode($icon-humidity-lg);
    }
}

.icon-airquality-lg {
    &::before {
        content: unicode($icon-airquality-lg);
    }
}

.icon-leaf-lg {
    &::before {
        content: unicode($icon-leaf-lg);
    }
}

.icon-co2-lg {
    &::before {
        content: unicode($icon-co2-lg);
    }
}

.icon-bug-lg {
    &::before {
        content: unicode($icon-bug-lg);
    }
}

.icon-light-lg {
    &::before {
        content: unicode($icon-light-lg);
    }
}

.icon-recipe2-lg {
    &::before {
        content: unicode($icon-recipe2-lg);
    }
}

.icon-fan-lg {
    &::before {
        content: unicode($icon-fan-lg);
    }
}

.icon-recipe1-lg {
    &::before {
        content: unicode($icon-recipe1-lg);
    }
}

.icon-watertank-lg {
    &::before {
        content: unicode($icon-watertank-lg);
    }
}

.icon-book-open-xl {
    &::before {
        content: unicode($icon-book-open-xl);
    }
}

.icon-book-open-lg {
    &::before {
        content: unicode($icon-book-open-lg);
    }
}

.icon-book-open-bold {
    &::before {
        content: unicode($icon-book-open-bold);
    }
}

.icon-bubbles-lg {
    &::before {
        content: unicode($icon-bubbles-lg);
    }
}

.icon-cloud-lg {
    &::before {
        content: unicode($icon-cloud-lg);
    }
}

.icon-cloud-bold {
    &::before {
        content: unicode($icon-cloud-bold);
    }
}

.icon-desktop {
    &::before {
        content: unicode($icon-desktop);
    }
}

.icon-filter {
    &::before {
        content: unicode($icon-filter);
    }
}

.icon-hourglass {
    &::before {
        content: unicode($icon-hourglass);
    }
}

.icon-lego-lg {
    &::before {
        content: unicode($icon-lego-lg);
    }
}

.icon-phone {
    &::before {
        content: unicode($icon-phone);
    }
}

.icon-pin-lg {
    &::before {
        content: unicode($icon-pin-lg);
    }
}

.icon-share-lg {
    &::before {
        content: unicode($icon-share-lg);
    }
}

.icon-sunrise {
    &::before {
        content: unicode($icon-sunrise);
    }
}

.icon-sunset {
    &::before {
        content: unicode($icon-sunset);
    }
}

.icon-tablet {
    &::before {
        content: unicode($icon-tablet);
    }
}

.icon-user-female-lg {
    &::before {
        content: unicode($icon-user-female-lg);
    }
}

.icon-user-lg {
    &::before {
        content: unicode($icon-user-lg);
    }
}

.icon-windspeed-lg {
    &::before {
        content: unicode($icon-windspeed-lg);
    }
}

.icon-ice-lg {
    &::before {
        content: unicode($icon-ice-lg);
    }
}

.icon-weather-sunny {
    &::before {
        content: unicode($icon-weather-sunny);
    }
}

.icon-waterdrop-xl {
    &::before {
        content: unicode($icon-waterdrop-xl);
    }
}

.icon-waterdrops-lg {
    &::before {
        content: unicode($icon-waterdrops-lg);
    }
}

.icon-weather-lg {
    &::before {
        content: unicode($icon-weather-lg);
    }
}

.icon-calculator-lg {
    &::before {
        content: unicode($icon-calculator-lg);
    }
}

.icon-equalizer-lg {
    &::before {
        content: unicode($icon-equalizer-lg);
    }
}

.icon-flame-lg {
    &::before {
        content: unicode($icon-flame-lg);
    }
}

.icon-temperature-lg {
    &::before {
        content: unicode($icon-temperature-lg);
    }
}

.icon-greenhouse-badge-heart-lg {
    &::before {
        content: unicode($icon-greenhouse-badge-heart-lg);
    }
}

.icon-greenhouse-lg {
    &::before {
        content: unicode($icon-greenhouse-lg);
    }
}

.icon-greenhouse-badge-thermometer-lg {
    &::before {
        content: unicode($icon-greenhouse-badge-thermometer-lg);
    }
}

.icon-shed-badge-waterdrop-lg {
    &::before {
        content: unicode($icon-shed-badge-waterdrop-lg);
    }
}

.icon-scenemacros-lg {
    &::before {
        content: unicode($icon-scenemacros-lg);
    }
}

.icon-home-lg {
    &::before {
        content: unicode($icon-home-lg);
    }
}

.icon-smileybad-xl {
    &::before {
        content: unicode($icon-smileybad-xl);
    }
}

.icon-smileyneutral-xl {
    &::before {
        content: unicode($icon-smileyneutral-xl);
    }
}

.icon-checkmark-lg {
    &::before {
        content: unicode($icon-checkmark-lg);
    }
}

.icon-calendar-lg {
    &::before {
        content: unicode($icon-calendar-lg);
    }
}

.icon-check-circle {
    &::before {
        content: unicode($icon-check-circle);
    }
}

.icon-log-out {
    &::before {
        content: unicode($icon-log-out);
    }
}

.icon-search {
    &::before {
        content: unicode($icon-search);
    }
}

.icon-bell-buzzing-lg {
    &::before {
        content: unicode($icon-bell-buzzing-lg);
    }
}

.icon-help {
    &::before {
        content: unicode($icon-help);
    }
}

.icon-help-outline-lg {
    &::before {
        content: unicode($icon-help-outline-lg);
    }
}

.icon-warning {
    &::before {
        content: unicode($icon-warning);
    }
}

.icon-warning-outline {
    &::before {
        content: unicode($icon-warning-outline);
    }
}

.icon-info {
    &::before {
        content: unicode($icon-info);
    }
}

.icon-info-outline {
    &::before {
        content: unicode($icon-info-outline);
    }
}

.icon-plus {
    &::before {
        content: unicode($icon-plus);
    }
}

.icon-plus-circle {
    &::before {
        content: unicode($icon-plus-circle);
    }
}

.icon-plus-light {
    &::before {
        content: unicode($icon-plus-light);
    }
}

.icon-cross {
    &::before {
        content: unicode($icon-cross);
    }
}

.icon-cross-circle {
    &::before {
        content: unicode($icon-cross-circle);
    }
}

.icon-cross-light {
    &::before {
        content: unicode($icon-cross-light);
    }
}

.icon-check-bold {
    &::before {
        content: unicode($icon-check-bold);
    }
}

.icon-check-light {
    &::before {
        content: unicode($icon-check-light);
    }
}

.icon-loop {
    &::before {
        content: unicode($icon-loop);
    }
}

.icon-reset {
    &::before {
        content: unicode($icon-reset);
    }
}

.icon-ban {
    &::before {
        content: unicode($icon-ban);
    }
}

.icon-unlink {
    &::before {
        content: unicode($icon-unlink);
    }
}

.icon-link {
    &::before {
        content: unicode($icon-link);
    }
}

.icon-language {
    &::before {
        content: unicode($icon-language);
    }
}

.icon-equalizer-alt {
    &::before {
        content: unicode($icon-equalizer-alt);
    }
}

.icon-linegraph {
    &::before {
        content: unicode($icon-linegraph);
    }
}

.icon-compass-lg {
    &::before {
        content: unicode($icon-compass-lg);
    }
}

.icon-layers {
    &::before {
        content: unicode($icon-layers);
    }
}

.icon-maintenance-lg {
    &::before {
        content: unicode($icon-maintenance-lg);
    }
}

.icon-energy {
    &::before {
        content: unicode($icon-energy);
    }
}

.icon-network {
    &::before {
        content: unicode($icon-network);
    }
}

.icon-electricity {
    &::before {
        content: unicode($icon-electricity);
    }
}

.icon-temperature-lg2 {
    &::before {
        content: unicode($icon-temperature-lg2);
    }
}

.icon-below-freezingpoint-lg {
    &::before {
        content: unicode($icon-below-freezingpoint-lg);
    }
}

.icon-transfer-vertical {
    &::before {
        content: unicode($icon-transfer-vertical);
    }
}

.icon-transfer-horizontal {
    &::before {
        content: unicode($icon-transfer-horizontal);
    }
}

.icon-previous {
    &::before {
        content: unicode($icon-previous);
    }
}

.icon-next {
    &::before {
        content: unicode($icon-next);
    }
}

.icon-double-chevron-down {
    &::before {
        content: unicode($icon-double-chevron-down);
    }
}

.icon-double-chevron-up {
    &::before {
        content: unicode($icon-double-chevron-up);
    }
}

.icon-double-chevron-right {
    &::before {
        content: unicode($icon-double-chevron-right);
    }
}

.icon-double-chevron-left {
    &::before {
        content: unicode($icon-double-chevron-left);
    }
}

.icon-chevron-left {
    &::before {
        content: unicode($icon-chevron-left);
    }
}

.icon-chevron-right {
    &::before {
        content: unicode($icon-chevron-right);
    }
}

.icon-menu-left {
    &::before {
        content: unicode($icon-menu-left);
    }
}

.icon-menu-right {
    &::before {
        content: unicode($icon-menu-right);
    }
}

.icon-circle-left {
    &::before {
        content: unicode($icon-circle-left);
    }
}

.icon-circle-up {
    &::before {
        content: unicode($icon-circle-up);
    }
}

.icon-circle-down {
    &::before {
        content: unicode($icon-circle-down);
    }
}

.icon-circle-right {
    &::before {
        content: unicode($icon-circle-right);
    }
}

.icon-sunny-weather {
    &::before {
        content: unicode($icon-sunny-weather);
    }
}

.icon-arrow-light-up-left {
    &::before {
        content: unicode($icon-arrow-light-up-left);
    }
}

.icon-arrow-light-up {
    &::before {
        content: unicode($icon-arrow-light-up);
    }
}

.icon-arrow-light-up-right {
    &::before {
        content: unicode($icon-arrow-light-up-right);
    }
}

.icon-arrow-light-right {
    &::before {
        content: unicode($icon-arrow-light-right);
    }
}

.icon-arrow-light-down-right {
    &::before {
        content: unicode($icon-arrow-light-down-right);
    }
}

.icon-arrow-light-down {
    &::before {
        content: unicode($icon-arrow-light-down);
    }
}

.icon-arrow-light-down-left {
    &::before {
        content: unicode($icon-arrow-light-down-left);
    }
}

.icon-arrow-left {
    &::before {
        content: unicode($icon-arrow-left);
    }
}

.icon-arrow-up-left {
    &::before {
        content: unicode($icon-arrow-up-left);
    }
}

.icon-arrow-up {
    &::before {
        content: unicode($icon-arrow-up);
    }
}

.icon-arrow-up-right {
    &::before {
        content: unicode($icon-arrow-up-right);
    }
}

.icon-arrow-right {
    &::before {
        content: unicode($icon-arrow-right);
    }
}

.icon-arrow-down-right {
    &::before {
        content: unicode($icon-arrow-down-right);
    }
}

.icon-arrow-down {
    &::before {
        content: unicode($icon-arrow-down);
    }
}

.icon-arrow-down-left {
    &::before {
        content: unicode($icon-arrow-down-left);
    }
}

.icon-arrow-light-left {
    &::before {
        content: unicode($icon-arrow-light-left);
    }
}

.icon-weather-cloudy-lg {
    &::before {
        content: unicode($icon-weather-cloudy-lg);
    }
}

.icon-weather-night-clear-lg {
    &::before {
        content: unicode($icon-weather-night-clear-lg);
    }
}

.icon-weather-night-cloudy-lg {
    &::before {
        content: unicode($icon-weather-night-cloudy-lg);
    }
}

.icon-weather-rainy-lg {
    &::before {
        content: unicode($icon-weather-rainy-lg);
    }
}

.icon-weather-snowy-lg {
    &::before {
        content: unicode($icon-weather-snowy-lg);
    }
}

.icon-weather-stormy-lg {
    &::before {
        content: unicode($icon-weather-stormy-lg);
    }
}

.icon-module .path1 {
    &::before {
        content: unicode($icon-module-path1);
        color: rgb(0 150 234);
    }
}

.icon-module .path2 {
    &::before {
        content: unicode($icon-module-path2);
        margin-left: -1em;
        color: rgb(0 0 0);
    }
}

.icon-module .path3 {
    &::before {
        content: unicode($icon-module-path3);
        margin-left: -1em;
        color: rgb(255 255 255);
    }
}

.icon-module .path4 {
    &::before {
        content: unicode($icon-module-path4);
        margin-left: -1em;
        color: rgb(0 0 0);
    }
}

.icon-module .path5 {
    &::before {
        content: unicode($icon-module-path5);
        margin-left: -1em;
        color: rgb(0 0 0);
    }
}

.icon-lock {
    &::before {
        content: unicode($icon-lock);
    }
}

.icon-comment {
    &::before {
        content: unicode($icon-comment);
    }
}

.icon-option-vertical {
    &::before {
        content: unicode($icon-option-vertical);
    }
}

.icon-option-horizontal {
    &::before {
        content: unicode($icon-option-horizontal);
    }
}

.icon-minus {
    &::before {
        content: unicode($icon-minus);
    }
}

.icon-minus-sign {
    &::before {
        content: unicode($icon-minus-sign);
    }
}

.icon-ok-circle {
    &::before {
        content: unicode($icon-ok-circle);
    }
}

.icon-remove-circle {
    &::before {
        content: unicode($icon-remove-circle);
    }
}

.icon-exclamation-sign {
    &::before {
        content: unicode($icon-exclamation-sign);
    }
}

.icon-copyright-mark {
    &::before {
        content: unicode($icon-copyright-mark);
    }
}

.icon-registration-mark {
    &::before {
        content: unicode($icon-registration-mark);
    }
}

.icon-heart-empty {
    &::before {
        content: unicode($icon-heart-empty);
    }
}

.icon-heart {
    &::before {
        content: unicode($icon-heart);
    }
}

.icon-star-lg {
    &::before {
        content: unicode($icon-star-lg);
    }
}

.icon-star-empty-lg {
    &::before {
        content: unicode($icon-star-empty-lg);
    }
}

.icon-wrench {
    &::before {
        content: unicode($icon-wrench);
    }
}

.icon-envelope {
    &::before {
        content: unicode($icon-envelope);
    }
}

.icon-paperclip {
    &::before {
        content: unicode($icon-paperclip);
    }
}

.icon-th-large {
    &::before {
        content: unicode($icon-th-large);
    }
}

.icon-grid-lg {
    &::before {
        content: unicode($icon-grid-lg);
    }
}

.icon-menu-lg {
    &::before {
        content: unicode($icon-menu-lg);
    }
}

.icon-th-list {
    &::before {
        content: unicode($icon-th-list);
    }
}

.icon-list-alt {
    &::before {
        content: unicode($icon-list-alt);
    }
}

.icon-zoom-in {
    &::before {
        content: unicode($icon-zoom-in);
    }
}

.icon-zoom-out {
    &::before {
        content: unicode($icon-zoom-out);
    }
}

.icon-off {
    &::before {
        content: unicode($icon-off);
    }
}

.icon-dashboard {
    &::before {
        content: unicode($icon-dashboard);
    }
}

.icon-qrcode-lg {
    &::before {
        content: unicode($icon-qrcode-lg);
    }
}

.icon-barcode-lg {
    &::before {
        content: unicode($icon-barcode-lg);
    }
}

.icon-tag {
    &::before {
        content: unicode($icon-tag);
    }
}

.icon-tags {
    &::before {
        content: unicode($icon-tags);
    }
}

.icon-bookmark {
    &::before {
        content: unicode($icon-bookmark);
    }
}

.icon-camera {
    &::before {
        content: unicode($icon-camera);
    }
}

.icon-facetime-video {
    &::before {
        content: unicode($icon-facetime-video);
    }
}

.icon-picture {
    &::before {
        content: unicode($icon-picture);
    }
}

.icon-flag {
    &::before {
        content: unicode($icon-flag);
    }
}

.icon-map-marker {
    &::before {
        content: unicode($icon-map-marker);
    }
}

.icon-record {
    &::before {
        content: unicode($icon-record);
    }
}

.icon-screenshot-lg {
    &::before {
        content: unicode($icon-screenshot-lg);
    }
}

.icon-contrast {
    &::before {
        content: unicode($icon-contrast);
    }
}

.icon-music {
    &::before {
        content: unicode($icon-music);
    }
}

.icon-film {
    &::before {
        content: unicode($icon-film);
    }
}

.icon-fast-backward {
    &::before {
        content: unicode($icon-fast-backward);
    }
}

.icon-step-backward {
    &::before {
        content: unicode($icon-step-backward);
    }
}

.icon-backward {
    &::before {
        content: unicode($icon-backward);
    }
}

.icon-play {
    &::before {
        content: unicode($icon-play);
    }
}

.icon-pause {
    &::before {
        content: unicode($icon-pause);
    }
}

.icon-stop {
    &::before {
        content: unicode($icon-stop);
    }
}

.icon-forward {
    &::before {
        content: unicode($icon-forward);
    }
}

.icon-step-forward {
    &::before {
        content: unicode($icon-step-forward);
    }
}

.icon-fast-forward {
    &::before {
        content: unicode($icon-fast-forward);
    }
}

.icon-volume-off {
    &::before {
        content: unicode($icon-volume-off);
    }
}

.icon-volume-down {
    &::before {
        content: unicode($icon-volume-down);
    }
}

.icon-volume-up {
    &::before {
        content: unicode($icon-volume-up);
    }
}

.icon-repeat {
    &::before {
        content: unicode($icon-repeat);
    }
}

.icon-refresh {
    &::before {
        content: unicode($icon-refresh);
    }
}

.icon-move {
    &::before {
        content: unicode($icon-move);
    }
}

.icon-resize-full {
    &::before {
        content: unicode($icon-resize-full);
    }
}

.icon-resize-small {
    &::before {
        content: unicode($icon-resize-small);
    }
}

.icon-fullscreen {
    &::before {
        content: unicode($icon-fullscreen);
    }
}

.icon-resize-vertical {
    &::before {
        content: unicode($icon-resize-vertical);
    }
}

.icon-resize-horizontal {
    &::before {
        content: unicode($icon-resize-horizontal);
    }
}

.icon-retweet {
    &::before {
        content: unicode($icon-retweet);
    }
}

.icon-shuffle {
    &::before {
        content: unicode($icon-shuffle);
    }
}

.icon-sort {
    &::before {
        content: unicode($icon-sort);
    }
}

.icon-eye-open {
    &::before {
        content: unicode($icon-eye-open);
    }
}

.icon-eye-close {
    &::before {
        content: unicode($icon-eye-close);
    }
}

.icon-folder-close {
    &::before {
        content: unicode($icon-folder-close);
    }
}

.icon-folder-open {
    &::before {
        content: unicode($icon-folder-open);
    }
}

.icon-file {
    &::before {
        content: unicode($icon-file);
    }
}

.icon-thumbs-up {
    &::before {
        content: unicode($icon-thumbs-up);
    }
}

.icon-thumbs-down {
    &::before {
        content: unicode($icon-thumbs-down);
    }
}

.icon-hand-right {
    &::before {
        content: unicode($icon-hand-right);
    }
}

.icon-hand-left {
    &::before {
        content: unicode($icon-hand-left);
    }
}

.icon-hand-up {
    &::before {
        content: unicode($icon-hand-up);
    }
}

.icon-hand-down {
    &::before {
        content: unicode($icon-hand-down);
    }
}

.icon-sort-by-alphabet {
    &::before {
        content: unicode($icon-sort-by-alphabet);
    }
}

.icon-sort-by-alphabet-alt {
    &::before {
        content: unicode($icon-sort-by-alphabet-alt);
    }
}

.icon-sort-by-order {
    &::before {
        content: unicode($icon-sort-by-order);
    }
}

.icon-sort-by-order-alt {
    &::before {
        content: unicode($icon-sort-by-order-alt);
    }
}

.icon-sort-by-attributes {
    &::before {
        content: unicode($icon-sort-by-attributes);
    }
}

.icon-sort-by-attributes-alt {
    &::before {
        content: unicode($icon-sort-by-attributes-alt);
    }
}

.icon-checked {
    &::before {
        content: unicode($icon-checked);
    }
}

.icon-unchecked {
    &::before {
        content: unicode($icon-unchecked);
    }
}

.icon-expand {
    &::before {
        content: unicode($icon-expand);
    }
}

.icon-collapse-down {
    &::before {
        content: unicode($icon-collapse-down);
    }
}

.icon-collapse-up {
    &::before {
        content: unicode($icon-collapse-up);
    }
}

.icon-edit {
    &::before {
        content: unicode($icon-edit);
    }
}

.icon-log-in {
    &::before {
        content: unicode($icon-log-in);
    }
}

.icon-new-window {
    &::before {
        content: unicode($icon-new-window);
    }
}

.icon-save {
    &::before {
        content: unicode($icon-save);
    }
}

.icon-open {
    &::before {
        content: unicode($icon-open);
    }
}

.icon-saved {
    &::before {
        content: unicode($icon-saved);
    }
}

.icon-import {
    &::before {
        content: unicode($icon-import);
    }
}

.icon-export {
    &::before {
        content: unicode($icon-export);
    }
}

.icon-inbox {
    &::before {
        content: unicode($icon-inbox);
    }
}

.icon-floppy-disk {
    &::before {
        content: unicode($icon-floppy-disk);
    }
}

.icon-floppy-saved {
    &::before {
        content: unicode($icon-floppy-saved);
    }
}

.icon-floppy-remove {
    &::before {
        content: unicode($icon-floppy-remove);
    }
}

.icon-floppy-save {
    &::before {
        content: unicode($icon-floppy-save);
    }
}

.icon-floppy-open {
    &::before {
        content: unicode($icon-floppy-open);
    }
}

.icon-download-alt {
    &::before {
        content: unicode($icon-download-alt);
    }
}

.icon-erase {
    &::before {
        content: unicode($icon-erase);
    }
}

.icon-tasks {
    &::before {
        content: unicode($icon-tasks);
    }
}

.icon-stats {
    &::before {
        content: unicode($icon-stats);
    }
}

.icon-play-circle {
    &::before {
        content: unicode($icon-play-circle);
    }
}

.icon-cloud-download {
    &::before {
        content: unicode($icon-cloud-download);
    }
}

.icon-cloud-upload {
    &::before {
        content: unicode($icon-cloud-upload);
    }
}

.icon-duplicate {
    &::before {
        content: unicode($icon-duplicate);
    }
}

.icon-flash {
    &::before {
        content: unicode($icon-flash);
    }
}

.icon-cutlery {
    &::before {
        content: unicode($icon-cutlery);
    }
}

.icon-grain-lg {
    &::before {
        content: unicode($icon-grain-lg);
    }
}

.icon-plane {
    &::before {
        content: unicode($icon-plane);
    }
}

.icon-globe {
    &::before {
        content: unicode($icon-globe);
    }
}

.icon-credit-card {
    &::before {
        content: unicode($icon-credit-card);
    }
}

.icon-hdd {
    &::before {
        content: unicode($icon-hdd);
    }
}

.icon-briefcase {
    &::before {
        content: unicode($icon-briefcase);
    }
}

.icon-tree-deciduous {
    &::before {
        content: unicode($icon-tree-deciduous);
    }
}

.icon-cd {
    &::before {
        content: unicode($icon-cd);
    }
}

.icon-blackboard {
    &::before {
        content: unicode($icon-blackboard);
    }
}

.icon-bed {
    &::before {
        content: unicode($icon-bed);
    }
}

.icon-lamp {
    &::before {
        content: unicode($icon-lamp);
    }
}

.icon-shopping-cart {
    &::before {
        content: unicode($icon-shopping-cart);
    }
}

.icon-scissors {
    &::before {
        content: unicode($icon-scissors);
    }
}

.icon-scale {
    &::before {
        content: unicode($icon-scale);
    }
}

.icon-modal-window {
    &::before {
        content: unicode($icon-modal-window);
    }
}

.icon-education-lg {
    &::before {
        content: unicode($icon-education-lg);
    }
}

.icon-send {
    &::before {
        content: unicode($icon-send);
    }
}

.icon-earphone {
    &::before {
        content: unicode($icon-earphone);
    }
}

.icon-save-file2 {
    &::before {
        content: unicode($icon-save-file2);
    }
}

.icon-open-file2 {
    &::before {
        content: unicode($icon-open-file2);
    }
}

.icon-copy2 {
    &::before {
        content: unicode($icon-copy2);
    }
}

.icon-paste2 {
    &::before {
        content: unicode($icon-paste2);
    }
}

.icon-level-up-lg {
    &::before {
        content: unicode($icon-level-up-lg);
    }
}

.icon-console {
    &::before {
        content: unicode($icon-console);
    }
}

.icon-compressed {
    &::before {
        content: unicode($icon-compressed);
    }
}

.icon-triangle-right {
    &::before {
        content: unicode($icon-triangle-right);
    }
}

.icon-triangle-left {
    &::before {
        content: unicode($icon-triangle-left);
    }
}

.icon-triangle-bottom {
    &::before {
        content: unicode($icon-triangle-bottom);
    }
}

.icon-triangle-top {
    &::before {
        content: unicode($icon-triangle-top);
    }
}

.icon-menu-down {
    &::before {
        content: unicode($icon-menu-down);
    }
}

.icon-menu-up {
    &::before {
        content: unicode($icon-menu-up);
    }
}

.icon-share-alt {
    &::before {
        content: unicode($icon-share-alt);
    }
}

.icon-circle-arrow-right {
    &::before {
        content: unicode($icon-circle-arrow-right);
    }
}

.icon-circle-arrow-left {
    &::before {
        content: unicode($icon-circle-arrow-left);
    }
}

.icon-circle-arrow-up {
    &::before {
        content: unicode($icon-circle-arrow-up);
    }
}

.icon-circle-arrow-down {
    &::before {
        content: unicode($icon-circle-arrow-down);
    }
}

.icon-circle-arrow-down-outline {
    &::before {
        content: unicode($icon-circle-arrow-down-outline);
    }
}

.icon-circle-arrow-up-outline {
    &::before {
        content: unicode($icon-circle-arrow-up-outline);
    }
}

.icon-chevron-up {
    &::before {
        content: unicode($icon-chevron-up);
    }
}

.icon-chevron-down {
    &::before {
        content: unicode($icon-chevron-down);
    }
}

.icon-alert-outline-lg {
    &::before {
        content: unicode($icon-alert-outline-lg);
    }
}

.icon-dashboard-lg {
    &::before {
        content: unicode($icon-dashboard-lg);
    }
}

.icon-manualoverride-lg {
    &::before {
        content: unicode($icon-manualoverride-lg);
    }
}

.icon-shed-badge-flame-lg {
    &::before {
        content: unicode($icon-shed-badge-flame-lg);
    }
}

.icon-settings {
    &::before {
        content: unicode($icon-settings);
    }
}

.icon-settings-outline-lg {
    &::before {
        content: unicode($icon-settings-outline-lg);
    }
}

.icon-alert2-lg {
    &::before {
        content: unicode($icon-alert2-lg);
    }
}

.icon-edit2 {
    &::before {
        content: unicode($icon-edit2);
    }
}

.icon-edit-lg {
    &::before {
        content: unicode($icon-edit-lg);
    }
}

.icon-bell-lg {
    &::before {
        content: unicode($icon-bell-lg);
    }
}

.icon-envelope-xl {
    &::before {
        content: unicode($icon-envelope-xl);
    }
}

.icon-cloud-download-xl {
    &::before {
        content: unicode($icon-cloud-download-xl);
    }
}

.icon-cloud-upload-xl {
    &::before {
        content: unicode($icon-cloud-upload-xl);
    }
}

.icon-leafs-lg {
    &::before {
        content: unicode($icon-leafs-lg);
    }
}

.icon-smileygood-xl {
    &::before {
        content: unicode($icon-smileygood-xl);
    }
}

.icon-alert {
    &::before {
        content: unicode($icon-alert);
    }
}

.icon-stars1-xl {
    &::before {
        content: unicode($icon-stars1-xl);
    }
}

.icon-stars2-xl {
    &::before {
        content: unicode($icon-stars2-xl);
    }
}

.icon-stars3-xl {
    &::before {
        content: unicode($icon-stars3-xl);
    }
}

.icon-alert-outline2-lg {
    &::before {
        content: unicode($icon-alert-outline2-lg);
    }
}

.icon-download-lg {
    &::before {
        content: unicode($icon-download-lg);
    }
}

.icon-configure-lg {
    &::before {
        content: unicode($icon-configure-lg);
    }
}

.icon-edit-xl {
    &::before {
        content: unicode($icon-edit-xl);
    }
}

.icon-arrow-updown {
    &::before {
        content: unicode($icon-arrow-updown);
    }
}

.icon-formule {
    &::before {
        content: unicode($icon-formule);
    }
}

.icon-clock-fill {
    &::before {
        content: unicode($icon-clock-fill);
    }
}

.icon-sort-arrow-down {
    &::before {
        content: unicode($icon-sort-arrow-down);
    }
}

.icon-sort-arrow-up {
    &::before {
        content: unicode($icon-sort-arrow-up);
    }
}

.icon-sort-down-bold {
    &::before {
        content: unicode($icon-sort-down-bold);
    }
}

.icon-sort-up-bold {
    &::before {
        content: unicode($icon-sort-up-bold);
    }
}

.icon-copy {
    &::before {
        content: unicode($icon-copy);
    }
}

.icon-transpose {
    &::before {
        content: unicode($icon-transpose);
    }
}
