/*  ==========================================================================
    Borders
    ========================================================================== */

/* width */
$border-width-light: 1px;
$border-width-regular: 2px;

/* radius */
$border-radius-small: 2px;
$border-radius: 4px;
$border-radius-circle: 50%;
