/*  ==========================================================================
    Semantic z-index list

    TODO: implement z-index map through z() function
    ========================================================================== */

$z-indexes: (
    elements: (
        default,
        active,
        hover,
    ),
    layout: (
        footer,
        main,
        sidebar-local,
        navigation-primary,
        header,
        sidebar-global,
    ),
    component: (
        table-fixed action-menu,
        action-menu-backdrop,
        popover,
        tooltip,
        dialog-background,
        dialog,
        notifications,
    ),
    loaders: (
        spinner,
        preloader,
    ),
);

/* General */
$z-index-initial: auto;
$z-index-inherit: inherit;
$z-index-below: -1;
$z-index-base: 0;
$z-index-above: 1;
$z-index-focus: 2;
$z-index-sticky: 3;

/* Layout */
$z-index-footer: 100;
$z-index-fab: 110;
$z-index-filter-bar: 110;
$z-index-sidebar-local: 120;
$z-index-navigation-primary: 130;
$z-index-header-global: 150;

/* Patterns */
$z-index-popover: 1010;
$z-index-action-menu-backdrop: 1015;
$z-index-action-menu: 1020;
$z-index-tooltip: 1030;
$z-index-overlay: 1035;
$z-index-global-background: 1040;
$z-index-sidebar-global: 1045;
$z-index-dialog-background: 1050;
$z-index-dialog: 1055;
$z-index-notifications: 1060;
$z-index-notification-toasters: $z-index-notifications;

$z-index-loader: 2000;
$z-index-preloader: 2010;
