/*  ==========================================================================
    Typography
    ========================================================================== */

@use 'sass:math';

/*  Custom font
    ========================================================================== */

/* Lato */
$lato-version: '1.0.0';
$lato-path: $font-path;
$lato-family: 'Lato';
$lato-name: 'lato';

$lato-light-name: 'light';
$lato-light-italic-name: 'lightitalic';
$lato-light-weight: 300;

$lato-regular-name: 'regular';
$lato-regular-italic-name: 'italic';
$lato-regular-weight: 400;

$lato-semibold-name: 'semibold';
$lato-semibold-italic-name: 'semibolditalic';
$lato-semibold-weight: 600;

$lato-bold-name: 'bold';
$lato-bold-italic-name: 'bolditalic';
$lato-bold-weight: 700;

/* Noto */
$noto-version: '1.0.0';
$noto-path: $font-path;
$noto-family: 'Noto Sans';
$noto-name: 'notosans';

$noto-regular-name: 'regular';
$noto-regular-italic-name: 'italic';
$noto-regular-weight: 400;

$noto-bold-name: 'bold';
$noto-bold-italic-name: 'bolditalic';
$noto-bold-weight: 700;

/*  Font properties
    ========================================================================== */

/* Family */
$font-family-base: $lato-family, 'Helvetica Neue', arial, sans-serif;
$font-family-headings: $noto-family, 'Helvetica Neue', arial, sans-serif;
$font-family-monospace: menlo, monaco, consolas, 'Courier New', monospace;

/* Weight */
$font-weight-light: $lato-light-weight;
$font-weight-regular: $lato-regular-weight;
$font-weight-semibold: $lato-semibold-weight;
$font-weight-bold: $lato-bold-weight;

/* Size unitless */
$font-xs: 11;
$font-sm: 13;
$font-md: 14;
$font-lg: 16;
$font-xl: 20;
$font-2xl: 24;
$font-3xl: 32;
$font-4xl: 40;

$font-base: $font-md;

/* Size pixels */
$font-size-xs: $font-xs * 1px;
$font-size-sm: $font-sm * 1px;
$font-size-md: $font-md * 1px;
$font-size-lg: $font-lg * 1px;
$font-size-xl: $font-xl * 1px;
$font-size-2xl: $font-2xl * 1px;
$font-size-3xl: $font-3xl * 1px;
$font-size-4xl: $font-4xl * 1px;

$font-size-base: $font-size-md;

/*  Line height

    Unitless line heights are recommended due to the fact that child elements
    will inherit the raw number value, rather than the computed value.

    With this, child elements can compute their line heights based on their
    computed font size, rather than inheriting an arbitrary value from a parent
    that is more likely to need overriding.
    ========================================================================== */

/* Line height leadings */
$line-height-base-unitless: 20;

$line-height-xs-unitless: $line-height-base-unitless;
$line-height-sm-unitless: $line-height-base-unitless;
$line-height-md-unitless: $line-height-base-unitless;
$line-height-lg-unitless: 24;
$line-height-xl-unitless: 28;
$line-height-2xl-unitless: 32;
$line-height-3xl-unitless: 40;
$line-height-4xl-unitless: 48;
$line-height-5xl-unitless: 64;

/* Line height unitless */
$line-height-base: math.div($line-height-base-unitless, $font-base); //1.42857143;
$line-height: $line-height-base;

$line-height-xs: math.div($line-height-xs-unitless, $font-xs); // 2
$line-height-sm: math.div($line-height-sm-unitless, $font-sm); // 1.66666667
$line-height-md: math.div($line-height-md-unitless, $font-md); // 1.42857143;
$line-height-md-extra: math.div($line-height-lg-unitless, $font-base); // 1.71429
$line-height-lg: math.div($line-height-lg-unitless, $font-lg); // 1.5
$line-height-lg-extra: math.div($line-height-xl-unitless, $font-lg); // 1.75
$line-height-xl: math.div($line-height-xl-unitless, $font-xl); // 1.4
$line-height-xl-extra: math.div($line-height-2xl-unitless, $font-xl); // 1.6
$line-height-2xl: math.div($line-height-2xl-unitless, $font-2xl); // 1.33333333
$line-height-2xl-extra: math.div($line-height-3xl-unitless, $font-2xl); // 1.66666667
$line-height-3xl: math.div($line-height-3xl-unitless, $font-3xl); // 1.25
$line-height-3xl-extra: math.div($line-height-3xl-unitless, $font-3xl); // 1.5
$line-height-4xl: math.div($line-height-4xl-unitless, $font-4xl); // 1.2
$line-height-4xl-extra: math.div($line-height-5xl-unitless, $font-4xl); // 1.33333333

/* Line height computed */
$line-height-base-computed: $line-height-base-unitless * 1px;
$line-height-computed: $line-height-base-computed;

$line-height-xs-computed: $line-height-xs-unitless * 1px;
$line-height-sm-computed: $line-height-sm-unitless * 1px;
$line-height-md-computed: $line-height-md-unitless * 1px;
$line-height-md-extra-computed: $line-height-lg-unitless * 1px;
$line-height-lg-computed: $line-height-lg-unitless * 1px;
$line-height-lg-extra-computed: $line-height-xl-unitless * 1px;
$line-height-xl-computed: $line-height-xl-unitless * 1px;
$line-height-xl-extra-computed: $line-height-2xl-unitless * 1px;
$line-height-2xl-computed: $line-height-2xl-unitless * 1px;
$line-height-2xl-extra-computed: $line-height-3xl-unitless * 1px;
$line-height-3xl-computed: $line-height-3xl-unitless * 1px;
$line-height-3xl-extra-computed: $line-height-4xl-unitless * 1px;
$line-height-4xl-computed: $line-height-4xl-unitless * 1px;
$line-height-4xl-extra-computed: $line-height-5xl-unitless * 1px;

/*  Text
    ========================================================================== */

$text-large-font-family: $font-family-headings;
$text-large-font-weight: $noto-bold-weight;

/*  Headers
    ========================================================================== */

/* Font size */
$font-size-h1: $font-size-2xl;
$font-size-h2: $font-size-xl;
$font-size-h3: $font-size-lg;
$font-size-h4: $font-size-base;
$font-size-h5: $font-size-base;
$font-size-h6: $font-size-base;

/* Line height */
$line-height-h1: $line-height-2xl;
$line-height-h2: $line-height-xl;
$line-height-h3: $line-height-lg;
$line-height-h4: $line-height-base;
$line-height-h5: $line-height-base;
$line-height-h6: $line-height-base;

/* Font family */
$font-family-h1: $font-family-headings;
$font-family-h2: $font-family-headings;
$font-family-h3: $font-family-headings;
$font-family-h4: $font-family-headings;
$font-family-h5: $font-family-headings;
$font-family-h6: $font-family-headings;

/* Font weight */
$font-weight-h1: $text-large-font-weight;
$font-weight-h2: $text-large-font-weight;
$font-weight-h3: $text-large-font-weight;
$font-weight-h4: $text-large-font-weight;
$font-weight-h5: $text-large-font-weight;
$font-weight-h6: $text-large-font-weight;

/*  Specific html element and component variables
    ========================================================================== */

$blockquote-font-size: $font-size-lg;
