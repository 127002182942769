/*  ==========================================================================
    Datepicker
    ========================================================================== */

.datepicker {
    $block: &;

    width: 100%;

    /*  Width
        ========================================================================== */

    &-width-auto {
        width: auto;
    }

    /*  Base data attributes
        ========================================================================== */

    [data-hidden='true'] {
        pointer-events: none;
    }

    [disabled] {
        color: $color-text-muted;
        cursor: default;
    }

    /*  Popup
        Combined class because with `appendTo` option the popup is not a child of
        it's component container
        ========================================================================== */

    &.dp-popup {
        @include panel();

        z-index: $z-index-popover;
        margin: $datepicker-popup-margin;
        padding: $datepicker-popup-padding;
    }

    .dp {
        /*  Input
            ========================================================================== */

        &-picker-input {
            @include form-control($block, '.dp-picker-input');

            width: 100%;
        }

        /*  Base
            ========================================================================== */

        &-selected {
            @extend %datepicker-selected;
        }

        /*  Top navigation
            ========================================================================== */

        &-nav {
            &-header,
            &-btns-container {
                /* reset vendor styles */
                position: static;
                top: unset;
                right: unset;
                transform: none;
            }

            /* Header */
            &-header {
                position: absolute;
                top: 0;
                left: 0;
                width: $datepicker-nav-header-width;
                height: 100%;
                margin: $datepicker-nav-header-margin;
                padding: $datepicker-nav-header-padding;

                /* text version */
                > span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $color-text-soft;
                }

                /* button version */
                &-btn {
                    @extend %trigger;
                    @extend %trigger-layout-fixed;

                    width: 100%;
                    margin: 0;
                    color: $color-text;
                }

                > span,
                &-btn {
                    height: 100%;
                    font-size: $font-size-base;

                    &[data-hidden='true'] {
                        display: none;
                    }
                }
            }
        }

        &-calendar {
            &-wrapper {
                border: none;
            }

            &-weekday {
                width: $min-touch-area;
                border: none;
                line-height: $datepicker-weekday-height;
            }

            &-nav {
                &-container {
                    position: relative;
                    min-height: $datepicker-nav-button-size;
                    margin-bottom: $datepicker-nav-margin-bottom;
                    padding: 0 $datepicker-nav-button-size;
                    border: none;
                }

                /*  Navigation previous/next triggers
                    ========================================================================== */

                &-container-left,
                &-container-right {
                    position: absolute;
                    top: 0;
                }

                &-container-left {
                    left: 0;
                }

                &-container-right {
                    right: 0;
                }

                &-left,
                &-right {
                    @extend %trigger-layout;
                    @extend %trigger-layout-fixed;

                    margin: 0;

                    &:not([data-hidden='true']) {
                        @extend %trigger-style;

                        &::before {
                            font-size: $font-size-base;
                        }
                    }

                    &[data-hidden='true'] {
                        color: $color-text-muted;
                    }

                    &::before,
                    &::after {
                        width: auto;
                        height: auto;
                        border: none;
                        transform: none;
                    }

                    &::before {
                        @extend .far;

                        font-size: $font-size-base;
                    }
                }

                &-left {
                    @extend .fa-chevron-left;
                }

                &-right {
                    @extend .fa-chevron-right;
                }
            }
        }

        /*  Day / Month calendar
            ========================================================================== */

        &-day,
        &-month {
            &-calendar {
                &-container {
                    background-color: transparent;
                }
            }
        }

        &-calendar-day,
        &-calendar-month {
            &:not([disabled], .dp-selected) {
                @extend %trigger-style;
            }

            &.dp-selected {
                @extend %datepicker-selected;
            }
        }

        &-calendar-day.dp-current-day,
        &-calendar-month.dp-current-month {
            border: $border-width-regular solid transparent;
            font-weight: $font-weight-bold;

            &:not([disabled], .dp-selected) {
                border-color: $color-selected;
                color: $color-selected;
            }

            &[disabled] {
                border-color: $color-text-muted;
                color: $color-text-muted;
            }

            &.dp-selected {
                @extend %datepicker-selected;
            }
        }

        /*  Day calendar
            ========================================================================== */

        &-calendar-day {
            width: $datepicker-day-trigger-size;
            height: $datepicker-day-trigger-size;
            margin: $datepicker-day-trigger-margin;
            border-radius: $border-radius;
        }

        /*  Week calendar
            ========================================================================== */

        &-calendar-week {
            position: relative;
        }

        /*  Month calendar
            ========================================================================== */

        &-calendar-month {
            width: $datepicker-month-trigger-width;
            height: $datepicker-month-trigger-height;
            margin: $datepicker-month-trigger-margin;

            &,
            &:not([disabled], .dp-selected) {
                border-radius: $border-radius;
            }
        }

        /*  Time controls
            ========================================================================== */

        &-time-select-controls {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: $datepicker-time-select-controls-margin;
        }

        &-time-select-control {
            display: flex;
            flex-direction: column;

            &-up,
            &-down {
                @extend %trigger;
                @extend %trigger-layout-fixed;

                margin: 0;

                &::before {
                    @extend .far;

                    top: 0;
                    width: auto;
                    height: auto;
                    border: none;
                    font-size: $font-size-base;
                    transform: none;
                }
            }

            &-up {
                @extend .fa-chevron-up;
            }

            &-down {
                @extend .fa-chevron-down;
            }
        }

        &-time-select-separator {
            width: $datepicker-time-select-separator;
        }

        &-time-select-control-meridiem {
            margin-left: $datepicker-time-select-separator;
        }

        &-time-select-display-hours,
        &-time-select-display-minutes,
        &-time-select-display-seconds,
        &-time-select-display-meridiem {
            padding: $datepicker-time-select-display-padding;
        }

        /*  Extra info
            ========================================================================== */

        &-week-number {
            top: 0;
            font-size: $datepicker-week-number-font-size;
            color: $color-text-soft;
        }

        &-weekdays {
            margin-bottom: $datepicker-weekdays-margin-bottom;
            font-size: $font-size-sm;
            color: $color-text-soft;
        }
    }

    /*  Day - time calendar
        ========================================================================== */

    dp-day-time-calendar {
        dp-time-select {
            display: flex;
            justify-content: center;
            margin-top: $datepicker-time-select-day-calendar-offset-margin;
            padding-top: $datepicker-time-select-day-calendar-offset-padding;
            border: none;
            border-top: $border-width-light solid $color-divider;
        }
    }

    /*  Alignment
        ========================================================================== */

    &-text-left {
        .dp-picker-input {
            text-align: left;
        }
    }

    &-text-center {
        .dp-picker-input {
            text-align: center;
        }
    }

    &-text-right {
        .dp-picker-input {
            text-align: right;
        }
    }

    /*  Theme
        ========================================================================== */

    @include theme(light-blue) {
        .dp-picker-input {
            @extend %form-control--theme-on-light-blue;
        }
    }

    @include theme(dark-blue) {
        .dp-picker-input {
            @extend %form-control--theme-on-dark-blue;
        }
    }

    /*  State
    ========================================================================== */

    &.has-today-button {
        .dp-nav-header {
            right: $component-size-base;
            left: auto;
            width: $datepicker-has-today-button-nav-header-width;
            margin: 0;
            padding: 0;
        }

        .dp-current-location-btn {
            position: absolute;
            top: 0;
            left: 0;
            width: $component-size-base;
            height: $component-size-base;
            border-color: $color-blue-300;
            border-radius: $border-radius;
            background-color: transparent;
            background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2213%22%20height%3D%2215%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22#{encodecolor($color-green-blue-800)}%22%20d%3D%22M3.438%2010.813a.509.509%200%200%201-.329-.11.509.509%200%200%201-.109-.328V7.75c0-.128.036-.228.11-.3a.447.447%200%200%201%20.328-.138h2.624c.128%200%20.228.046.301.137a.364.364%200%200%201%20.137.301v2.625a.447.447%200%200%201-.137.328.407.407%200%200%201-.3.11H3.438ZM11.311%202.5c.365%200%20.675.128.93.383s.383.565.383.93v9.624c0%20.365-.128.675-.383.93a1.266%201.266%200%200%201-.93.383H1.688c-.365%200-.675-.128-.93-.383a1.266%201.266%200%200%201-.383-.93V3.813c0-.365.128-.675.383-.93s.565-.383.93-.383H3V1.187c0-.127.036-.227.11-.3A.447.447%200%200%201%203.437.75h.874c.128%200%20.228.046.301.137a.364.364%200%200%201%20.137.3V2.5h3.5V1.187c0-.127.036-.227.11-.3A.447.447%200%200%201%208.687.75h.874c.128%200%20.228.046.301.137a.364.364%200%200%201%20.137.3V2.5h1.313Zm0%2010.773V5.125H1.688v8.148c0%20.11.054.165.164.165h9.296c.11%200%20.165-.055.165-.165Z%22%2F%3E%3C%2Fsvg%3E');
            background-repeat: no-repeat;
            background-position: center;
            color: $color-green-blue-800;
            box-shadow: none;
            cursor: pointer;
            appearance: none;

            &:hover {
                background-color: $color-blue-100;
            }
        }

        .dp-calendar-nav-container-left {
            left: $component-size-base;
        }
    }
}
