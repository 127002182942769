/*  ==========================================================================
    Table collapsed
    ========================================================================== */

.table {
    $block: &;

    &--collapsed {
        border-spacing: 0;

        #{$block}__cell {
            border-bottom: $table__border_width solid $table_border-color;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &#{$block}--has-clickable-rows #{$block}__body #{$block}__row {
            @include focus-visible {
                #{$block}__cell {
                    border-bottom-color: $color-focus;
                }
            }
        }

        &#{$block}--has-selectable-rows,
        &#{$block}--has-dark-header {
            #{$block}__cell {
                &:first-child {
                    padding-left: grid-unit();
                }

                &:last-child {
                    padding-right: grid-unit();
                }

                &--header {
                    border-bottom: $table__border-width solid transparent;
                }
            }
        }
        #{$block}__row {
            &--full-width--first,
            &--first {
                #{$block}__cell {
                    border-top: $table__border-width solid transparent;
                }
            }
        }

        #{$block}__body #{$block}__row:last-child {
            #{$block}__cell {
                border-bottom: $table__border-width solid transparent;
            }
        }
    }
}
