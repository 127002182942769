/*  ==========================================================================
    Card
    ========================================================================== */

.card {
    $block: &;

    @include box-shadow(2);

    /*  Size
        ========================================================================== */

    @include card--size(md, $card-padding);
    @include card--size(xs, $card-padding-v-xs, $card-padding-h-xs);
    @include card--size(sm, $card-padding-sm);
    @include card--size(lg, $card-padding-lg);

    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: $border-radius;
    background: $card-bg;
    cursor: pointer;
    transition: all $animate-duration-short cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover,
    &:focus {
        @include box-shadow(6);
    }

    &:active {
        @include box-shadow(4);
    }

    &-action-primary {
        display: flex;
        flex: 1 0 auto;
    }

    /*  Image
        ========================================================================== */

    &-image {
        @include border-top-radius();

        height: $card-image-height;
        border: $border-width-light solid transparent;
        border-bottom: 0;
        background: $card-image-bg;
    }

    /*  Header
        ========================================================================== */

    &-indicator {
        min-width: $card-header-indicator-size;
        margin-left: $card-header-indicator-margin;
        font-size: $card-header-indicator-size;
        text-align: center;
        color: $card-header-indicator-color;
    }

    /*  Body
        ========================================================================== */

    &-body {
        flex: 1 0 auto;
    }

    /*  Footer
        ========================================================================== */

    &-footer {
        display: flex;
        flex: 0 0 auto;
        justify-content: flex-end;
        border-top: $card-border-width solid $card-footer-border-color;

        &.is-borderless {
            padding-top: 0;
            border-top-width: 0;
        }
    }

    /* bulk select checkbox */
    &-select {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $min-touch-area;
        height: $min-touch-area;
        margin-right: auto;
        margin-left: $card-footer-action-offset;
    }

    /* Actions */
    &-action {
        & + & {
            margin-left: $card-footer-action-margin;
        }
    }

    /* Overflow actions */
    &-overflow-menu {
        margin: 0 $card-footer-action-offset 0 $card-footer-action-margin;
    }

    .card-overflow-menu-trigger {
        @extend %trigger-layout;
        @extend %trigger-layout-fixed;

        width: $min-touch-area;
        height: $min-touch-area;
        margin: 0;
        border-radius: $card-overflow-trigger-radius;
        font-size: $card-overflow-trigger-size;
    }

    /*  Child element collapse
        ========================================================================== */

    &-element-collapse-padding {
        margin-right: -$card-padding;
        margin-left: -$card-padding;
    }

    @include focus-visible {
        @include box-shadow(4, bottom, true);

        #{$block} {
            &-image {
                border-color: $color-cta-primary;
            }
        }
    }
}
