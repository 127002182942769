.progress-circle {
    $block: &;

    position: relative;
    border-radius: $border-radius-circle;
    background-color: $progress-rail-bg;

    &__overlay,
    &__mask,
    &__indicator {
        position: absolute;
        border-radius: $border-radius-circle;
        transition: transform $animate-duration-long ease;
    }

    /* Radial progress indicator */
    &__indicator {
        background-color: $progress-circle__indicator-background;
    }

    /* Centered overlay */
    &__overlay {
        position: absolute;
        z-index: 1;
        background-color: $progress-circle__overlay-background;
    }

    /* Generate styles for progress indicator steps */
    @for $i from 1 through 100 {
        &[aria-valuenow='#{$i}'] {
            .progress-circle {
                &__mask--full,
                &__indicator {
                    transform: rotate($progress-circle-increment * $i);
                }

                &__indicator--fix {
                    transform: rotate($progress-circle-increment * $i * 2);
                }
            }
        }
    }

    /*  Sizes
        ========================================================================== */

    &--sm {
        @include progress-circle--size($progress-circle-sm-size, $progress-circle-sm-rail-size);
    }

    &--md {
        @include progress-circle--size($progress-circle-md-size, $progress-circle-md-rail-size);
    }

    &--lg {
        @include progress-circle--size($progress-circle-lg-size, $progress-circle-lg-rail-size);
    }

    /*  Themes
        ========================================================================== */

    @include theme('light-gray') {
        background-color: $progress-rail--theme-on-light-gray_bg;

        #{$block} {
            &__overlay {
                background-color: $progress-circle--theme-on-light-gray__overlay-background;
            }
        }
    }

    @include theme('light-blue') {
        background-color: $progress-rail--theme-on-light-blue_bg;

        #{$block} {
            &__overlay {
                background-color: $progress-circle--theme-on-light-blue__overlay-background;
            }
        }
    }

    @include theme('dark-blue') {
        background-color: $progress-rail--theme-on-dark-blue_bg;

        #{$block} {
            &__overlay {
                background-color: $progress-circle--theme-on-dark-blue__overlay-background;
            }

            &__indicator {
                background-color: $progress-indicator--theme-on-dark-blue_bg;
            }
        }
    }
}
