/*  ==========================================================================
    Table row
    ========================================================================== */

.table {
    $block: &;

    &__row {
        position: relative;
        height: 100%;
        border-radius: $table__cell_border-radius;

        > #{$block}__cell--sticky-header {
            display: none;
        }

        &-sticky-header--visible {
            > #{$block}__cell {
                display: none;
            }

            > #{$block}__cell--sticky-header {
                display: table-cell;
            }
        }

        &.is-selected {
            @include table__row--highlight($block);
        }
    }
}
