/*  ==========================================================================
    Table density
    ========================================================================== */

.table {
    $block: &;

    &--density-sm {
        #{$block}__cell {
            padding: $table--density-sm__cell_padding-vertical $table--density-sm__cell_padding-horizontal;

            &--interactive {
                padding: $table--density-sm__cell--interactive_padding;

                &-sm {
                    padding: $table--density-sm__cell--interactive-sm_padding;
                }
            }
        }

        &#{$block}--editable #{$block}__cell {
            padding-right: $table--editable--density-sm__cell_padding-horizontal;
            padding-left: $table--editable--density-sm__cell_padding-horizontal;

            &--editable {
                @include table__cell--editable__form-control-dense;
                @include table__cell--editable__datepicker-dense;
                @include table__cell--editable__radio-dense;
                @include table__cell--editable__checkbox-dense;

                padding: 0;

                &#{$block}__cell--header {
                    padding-right: 0;
                    padding-left: 0;
                }

                .form-input-group {
                    @include table__cell--editable__form-input-group__form-control-dense;
                }
            }
        }
    }
}
