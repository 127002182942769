/*  ==========================================================================
    Layout
    ========================================================================== */

/// Layout properties used to generate type based utility classes
/// @group configuration
$layout-utility-classes-properties-type-based: width, max-width, height, max-height !default;
/// Layout properties used to generate unit based utility classes
/// @group configuration
$layout-utility-classes-properties-unit-based: margin, padding !default;

/*  Generate basic layout classes for css properties
    ========================================================================== */

@each $breakpoint in map-keys($breakpoints-utility-classes) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @each $prop in $layout-utility-classes-properties-type-based {
            /* layout type based property values */
            @each $type, $value in $layout-types {
                /* single value (all sides for multi directional properties */
                .#{$prop}-#{$type}#{$infix} {
                    #{$prop}: $value !important;
                }

                @if $prop == margin or $prop == padding {
                    .#{$prop}-horizontal-#{$type}#{$infix} {
                        #{$prop}-right: $value !important;
                        #{$prop}-left: $value !important;
                    }

                    .#{$prop}-top-#{$type}#{$infix} {
                        #{$prop}-top: $value !important;
                    }

                    .#{$prop}-right-#{$type}#{$infix} {
                        #{$prop}-right: $value !important;
                    }

                    .#{$prop}-bottom-#{$type}#{$infix} {
                        #{$prop}-bottom: $value !important;
                    }

                    .#{$prop}-left-#{$type}#{$infix} {
                        #{$prop}-left: $value !important;
                    }
                }
            }
        }

        @each $prop in $layout-utility-classes-properties-unit-based {
            /* grid unit based property values */
            @each $size, $value in $grid-units {
                /* single value (all sides for multi directional properties) */
                .#{$prop}-#{$size}#{$infix} {
                    #{$prop}: $value !important;
                }

                @if $prop == margin or $prop == padding {
                    .#{$prop}-horizontal-#{$size}#{$infix} {
                        #{$prop}-right: $value !important;
                        #{$prop}-left: $value !important;
                    }

                    .#{$prop}-vertical-#{$size}#{$infix} {
                        #{$prop}-top: $value !important;
                        #{$prop}-bottom: $value !important;
                    }

                    .#{$prop}-top-#{$size}#{$infix} {
                        #{$prop}-top: $value !important;
                    }

                    .#{$prop}-right-#{$size}#{$infix} {
                        #{$prop}-right: $value !important;
                    }

                    .#{$prop}-bottom-#{$size}#{$infix} {
                        #{$prop}-bottom: $value !important;
                    }

                    .#{$prop}-left-#{$size}#{$infix} {
                        #{$prop}-left: $value !important;
                    }
                }

                @if ($prop == margin and $size != 0) {
                    /* single value (all sides for multi directional properties) */
                    .-#{$prop}-#{$size}#{$infix} {
                        #{$prop}: -$value !important;
                    }

                    .-#{$prop}-horizontal-#{$size}#{$infix} {
                        #{$prop}-right: -$value !important;
                        #{$prop}-left: -$value !important;
                    }

                    .-#{$prop}-vertical-#{$size}#{$infix} {
                        #{$prop}-top: -$value !important;
                        #{$prop}-bottom: -$value !important;
                    }

                    .-#{$prop}-top-#{$size}#{$infix} {
                        #{$prop}-top: -$value !important;
                    }

                    .-#{$prop}-right-#{$size}#{$infix} {
                        #{$prop}-right: -$value !important;
                    }

                    .-#{$prop}-bottom-#{$size}#{$infix} {
                        #{$prop}-bottom: -$value !important;
                    }

                    .-#{$prop}-left-#{$size}#{$infix} {
                        #{$prop}-left: -$value !important;
                    }
                }
            }
        }
    }
}

/*  Position
    ========================================================================== */
$positions: initial, inherit, static, relative, absolute, fixed, sticky;

@each $position in $positions {
    .position-#{$position} {
        position: $position !important;
    }
}

/*  Vertical align
    ========================================================================== */

.vertical-align-baseline {
    vertical-align: baseline !important;
}

.vertical-align-top {
    vertical-align: top !important;
}

.vertical-align-middle {
    vertical-align: middle !important;
}

.vertical-align-bottom {
    vertical-align: bottom !important;
}

.vertical-align-text-bottom {
    vertical-align: text-bottom !important;
}

.vertical-align-text-top {
    vertical-align: text-top !important;
}

/*  Overflow
    ========================================================================== */

.overflow-hidden {
    overflow: hidden hidden !important;
}

.overflow-x {
    overflow: auto hidden !important;
}

.overflow-y {
    overflow: hidden auto !important;
}

/*  Z-index
    ========================================================================== */

.z-above {
    z-index: $z-index-above !important;
}

.z-below {
    z-index: $z-index-below !important;
}

/*  Float
    ========================================================================== */

@each $breakpoint in map-keys($breakpoints-utility-classes) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        .float-left#{$infix} {
            float: left;
        }
        .float-right#{$infix} {
            float: right;
        }
        .float-none#{$infix} {
            float: none;
        }
    }
}
