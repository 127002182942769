/*  ==========================================================================
    Table clickable rows
    ========================================================================== */

.table {
    $block: &;

    &--has-clickable-rows #{$block}__body #{$block}__row {
        cursor: pointer;
        transition: all $animate-duration-short ease;

        &:hover {
            z-index: $z-index-sticky + $z-index-above;

            #{$block}__cell {
                transition: all $animate-duration-short ease;

                &:not(#{$block}__cell--highlight) {
                    background-color: $color-hover-background;
                    color: $color-hover-text;
                }

                &--highlight {
                    background-color: $table__cell--highlight_color-hover;
                }
            }
        }

        @include focus-visible {
            box-shadow: none;
        }

        &.is-active {
            @include table__row--highlight($block);

            &:first-child {
                background-color: $color-hover-background;
            }

            &:hover {
                background-color: $color-hover-background;

                #{$block}__cell {
                    &:not(#{$block}__cell--highlight) {
                        background-color: transparent;
                    }

                    &:first-child {
                        background-color: $color-hover-background;
                    }
                }
            }
        }
    }
}
