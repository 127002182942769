/*  ==========================================================================
    Extensions

    App specific global styling

    DO      -   Divide styling into logical semantic folders and partials.
            -   Use this file to @import partials.

    DON'T   -   Add any style declarations to this file.
            -   Add styling which can be added scoped (like component styles)
    ========================================================================== */

@import '@angular/cdk/overlay-prebuilt.css';
