/*  ==========================================================================
    Configuration

    Certain variables in the Priva Styles package are marked with a !default
    flag. These variables can be configured to accommodate your project needs.

    DO      -   Configure default variables defined in priva styles.

    DON'T   -   Use this section for project specific variables,
                these should be defined within the "extensions" or
                "overrides" section.

    ========================================================================== */

/* Path */
$asset-path: '/assets';
$enable-flex-utilities: true;
