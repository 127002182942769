/*  ==========================================================================
    Form Control Label
    ========================================================================== */

.form-control-label {
    display: inline-block;
    padding-bottom: $form-control-label-padding;
    font-weight: $form-control-label-font-weight;
    cursor: pointer;
}
