/*  ==========================================================================
    HTML element styling
    ========================================================================== */

html {
    direction: ltr;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba($color-black-900, 0);
    touch-action: manipulation;
}

body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $color-text;
}

/* Resets
   ========================================================================== */

/*
 * Universal element styling
 */

* {
    &,
    &::before,
    &::after {
        box-sizing: inherit;
    }
}

/*
 * Reset margin and padding from core elements
 */

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
blockquote,
figure,
button {
    margin: 0;
    padding: 0;
    font-size: inherit;
}

/**
 * Add the correct display in IE.
 */

main,
li {
    display: block;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
    font-weight: $font-weight-bold;
}

/* Text-level semantics
   ========================================================================== */

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $font-weight-regular;
}

/* Paragraphs */
p {
    margin: grid-unit() 0;

    &:first-child {
        margin-top: 0 !important;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }
}

/* Horizontal rule */
hr {
    margin: grid-unit(2) 0;
    border: 0;
    border-top: 1px solid darken($color-white, 5);
}

/* Interactive
 * 1. Inherit text-color in all browsers
 * 2. Always show pointer cursor
 * 3. Set default transition
 */

a,
button {
    color: inherit; /* 1 */
    cursor: pointer; /* 2 */
    transition: $animate-duration; /* 3 */
}

/* Link
 * 1. Remove default text decoration for links
 * 2. Remove outline
 * 3. Remove the gray background on active links in IE 10.
 * 4. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */

a {
    text-decoration: none; /* 1 */
    background-color: transparent; /* 3 */
    outline-width: 0; /* 2 */
    -webkit-text-decoration-skip: objects; /* 4 */

    &[disabled] {
        pointer-events: none;
    }
}

/* Button
 * 1. Reset the native appearance in all browsers
 * 2. Make IE behave
 */

button {
    overflow: visible; /* 2 */
    border: 0; /* 1 */
    font: inherit; /* 1 */
    -webkit-font-smoothing: inherit; /* 1 */
    letter-spacing: inherit; /* 1 */
    background: none; /* 1 */
}

/* List */
li {
    display: block;
}

/* Table
   ========================================================================== */

td,
th {
    padding: 0;
}

/* Form
   ========================================================================== */

textarea {
    resize: vertical;
}

/* Code
   ========================================================================== */

code,
kbd,
pre,
samp {
    font-family: $font-family-monospace;
}

/* Inline code */
code {
    font-size: 90%;
    background-color: $code-bg;
    color: $code-color;
}

/* User input typically entered via keyboard */
kbd {
    border-radius: $border-radius;
    font-size: 90%;
    background-color: $kbd-bg;
    color: $kbd-color;
    box-shadow: inset 0 -1px 0 rgba($color-black-900, 0.25);

    kbd {
        padding: 0;
        font-size: 100%;
        font-weight: $font-weight-bold;
        box-shadow: none;
    }
}

/* Blocks of code */
pre {
    display: block;
    margin: 0 0 ($line-height-computed * 0.5);
    padding: (($line-height-computed - 1) * 0.5);
    border: $pre-border-width solid $pre-border-color;
    border-radius: $border-radius;
    font-size: ($font-size-base - 1);
    line-height: $line-height-base;
    word-break: break-all;
    word-wrap: break-word;
    background-color: $pre-bg;
    color: $pre-color;

    // Account for some code outputs that place code tags in pre tags
    code {
        padding: 0;
        border-radius: 0;
        font-size: inherit;
        white-space: pre-wrap;
        background-color: transparent;
        color: inherit;
    }
}

/* SVG */
svg {
    text {
        font-family: $font-family-base;
    }
}

/* Marked content
   ========================================================================== */

mark {
    background-color: $color-orange-200;
}

/* Pseudo element styling
   ========================================================================== */

/* Focus styles for mouse interaction */
.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

/* Focus styles for keyboard interaction */
.js-focus-visible .focus-visible {
    @include focus();
}

/* Text selection */
::selection {
    background: $color-text-selection-bg;
    color: $color-text-selection-text;
}
