/*  ==========================================================================
    Forms
    ========================================================================== */

/*  Common form controls

    Shared size and type resets for form controls. Apply `.form-control` to any
    of the following form controls:

    select
    textarea
    input
    ========================================================================== */

.form-control {
    $block: &;

    @include form-control;

    /*  Element specific styling
    ========================================================================== */

    @at-root {
        textarea#{&} {
            height: auto;
        }

        select#{&} {
            @include form-control-select;
        }
    }

    /*  Size
    ========================================================================== */

    //TODO: to be refactored to '&-size-md' when all apps use angular form components
    &,
    &--size-md {
        @at-root {
            textarea#{&} {
                padding-top: $form-control-textarea-padding-v-size-md;
                padding-bottom: $form-control-textarea-padding-v-size-md;
            }
        }
    }

    &--size-sm {
        @at-root {
            textarea#{&} {
                padding-top: $form-control-textarea-padding-v-size-sm;
                padding-bottom: $form-control-textarea-padding-v-size-sm;
            }
        }
    }

    /* Alignment
    ========================================================================== */

    &-text-left {
        text-align: left;
    }

    &-text-center {
        text-align: center;
    }

    &-text-right {
        text-align: right;
    }

    /* Theme
   ========================================================================== */

    @include theme(light-blue) {
        &#{$block} {
            @extend %form-control--theme-on-light-blue;
        }
    }

    @include theme(dark-blue) {
        &#{$block} {
            @extend %form-control--theme-on-dark-blue;
        }
    }
}

/*  Checkboxes and radios
    ========================================================================== */

.form-control-label,
.form-control-help {
    /* first checkbox/radio is not the first html element in a form-group if a control-label is used */
    + .checkbox,
    + .radio {
        margin-top: -$radio-checkbox-spacing;
    }
}

/*  Custom form controls
    TODO: legacy styles to be removed in next major version
    ========================================================================== */

/* search */
.search-control {
    $block: &;

    position: relative;

    .search-icon {
        position: absolute;
        top: $search-control-icon-offset-top;
        left: $search-control-icon-offset-left;
        font-size: $search-control-icon-font-size;
        pointer-events: none;
    }

    .search-field {
        @include form-control($block, '.search-field');

        margin-right: 0; // reset default form field styling with 100% margin
        padding-left: $search-control-field-padding;
    }
}

/*  Static form control text
    ========================================================================== */

.form-control-static {
    $block: &;

    padding-top: $form-control-padding-top--size-md;
    padding-bottom: $form-control-padding-bottom--size-md;
    border: $form-control-border-w solid transparent;
}
