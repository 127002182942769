// TODO: Refactor FOR NEW APPSHELL
.login-sidebar {
    position: relative;
    top: 0;
    right: 0;
    float: left;
    overflow-y: auto;
    width: 100%;
    height: auto;
    min-height: calc(100% - 100px);
    margin-top: 100px;
    padding: grid-type-unit(5) grid-unit(4);
    background: linear-gradient(rgba($color-white, 1), rgba($color-white, 0.9));

    @include media-breakpoint-up(sm) {
        position: absolute;
        top: 0;
        bottom: 0;
        float: none;
        width: $breakpoint-xs-mid;
        margin-top: 0;
        background: rgba($color-white, 0.97); /* On larger screens the background isn't a gradient */
    }

    footer {
        font-size: 1rem;
    }
}

/* Custom checkbox: */

//  adds a large box over the original inputbox
.c-input input {
    position: absolute;
    z-index: $z-index-below;
    opacity: 0;
}

.c-input input:checked ~ .c-indicator {
    background-color: $color-selected;
}

.c-input input:active ~ .c-indicator {
    background-color: $color-pressed-background;
}

.c-indicator {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: grid-type-unit(7);
    height: grid-type-unit(7);
    border: thin solid $color-black-600;
    text-align: center;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    user-select: none;
}

// adds a checkmark when checked
.c-checkbox input:checked ~ .c-indicator {
    border-color: $color-selected;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=');
}

// hides the text selection color
.c-input::selection {
    background: transparent;
}
